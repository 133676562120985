import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ComboboxOption } from "parts/Combobox"

export type SignInStatus =
    | "success"
    | "sign-up-required"
    | "failure"

export type AppUserProfile = {
    id: string,
    activeId: string,
    name: string,
    nickname: string,
    privateId: string,
    phoneNumber: string,
}

export type UserAttributeKey =
    | "manager"
    | "staff"
    | "collaborator"

export type UserAttribute = UserAttributeKey | undefined

export type AuthorizationKey =
    | "demo-user-location-view"
    | "field-condition-soil"
    | "field-cultivation"
    | "field-picture-share"
    | "manage-users"
    | "region-weather"
    | "ufield-group"
    | "ufield-polygon"
    | "water-gate-status"
    | "water-gate-manage"
    | "loc-tracker"
    | "pest-control-plan"
    | "work-management"
    | "field-mc-sensing"

export type UserAuthorizations = {
    [key in AuthorizationKey]?: {
        writable: boolean,
    }
}


export type UserState = {
    signInStatus: SignInStatus | undefined, 
    appUserProfile: AppUserProfile,
    userAttribute: UserAttribute,
    userAuthorizations: UserAuthorizations,
    companyList: ComboboxOption[],
    userFieldGroupList?: any[],
}

export const defaultUserState: UserState = {
    signInStatus: undefined,
    appUserProfile: {
        id: "",
        activeId: "",
        name: "",
        nickname: "",
        privateId: "",
        phoneNumber: "",
    },
    userAttribute: undefined,
    userAuthorizations: {},
    companyList: [],
    userFieldGroupList: undefined,
}

const userStateSlice = createSlice({
    name: "user-state",
    initialState: defaultUserState,
    reducers: {
        setSignInStatus: (state: UserState, action: PayloadAction<SignInStatus | undefined>) => {
            state.signInStatus = action.payload
        },
        setAppUserProfile: (state: UserState, action: PayloadAction<AppUserProfile>) => {
            state.appUserProfile = action.payload
        },
        setUserAttribute: (state: UserState, action: PayloadAction<UserAttribute>) => {
            state.userAttribute = action.payload
        },
        setUserAuthorizations: (state: UserState, action: PayloadAction<UserAuthorizations>) => {
            state.userAuthorizations = action.payload
        },
        setCompanyList: (state: UserState, action: PayloadAction<ComboboxOption[]>) => {
            state.companyList = action.payload
        },
        setUserFieldGroupList: (state: UserState, action: PayloadAction<any[]>) => {
            state.userFieldGroupList = action.payload
        },
    }
})

export const {
    setSignInStatus,
    setAppUserProfile,
    setUserAttribute,
    setUserAuthorizations,
    setCompanyList,
    setUserFieldGroupList,
} = userStateSlice.actions

export const userStateReducer = userStateSlice.reducer
